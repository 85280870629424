import axios, {AxiosError} from 'axios';
import store from '@/store/index';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import {AxiosResponse} from 'axios';

const SecurityInstance = axios.create({
    baseURL: `${process.env.VUE_APP_SECURITY_BASE_URL}`,
    headers: {
        'Content-Type': 'application/json'
    }
});

SecurityInstance.interceptors.request.use(
    async (request) => {
        const token = store.getters.token;
        if (!token) return request;

        const user: any = jwt_decode(token);
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

        if (!isExpired) {
            request.headers.common.Authorization = `Bearer ${token}`;
            return request;
        }

        await axios
            .post(
                `${process.env.VUE_APP_SECURITY_BASE_URL}/auth/refresh`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then((response: AxiosResponse) => {
                const {access_token} = response.data;
                if (access_token) store.dispatch('login', access_token);
                request.headers.common.Authorization = `Bearer ${access_token}`;
            })
            .catch(() => {
                delete request.headers.common['Authorization'];
            });

        return request;
    },
    (error) => Promise.reject(error)
);

SecurityInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error: AxiosError) => {
        return Promise.reject(error.response || 'Algo salió mal');
    }
);

export default SecurityInstance;
