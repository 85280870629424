import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import store from '@/store/index';

import Main from '@/modules/main/main.vue';
import Login from '@/modules/login/login.vue';
import Register from '@/modules/register/register.vue';
//import Dashboard from '@/pages/dashboard/dashboard.vue';
import Profile from '@/pages/profile/profile.vue';
import ForgotPassword from '@/modules/forgot-password/forgot-password.vue';
import RecoverPassword from '@/modules/recover-password/recover-password.vue';
import UploadedFilesList from '@/modules/uploaded-files/list/uploaded-files-list.vue';
import UploadedFileDetail from '@/modules/uploaded-files/detail/detail.vue';
import PermissionDenied from '@/modules/permission-denied/permission-denied.vue';
import {permissions} from '@/enum/applicationPermissions';
import {defineRulesFor} from '@/config/rules';
import ability from '@/config/ability';
import {string} from 'yup';

defineRulesFor();

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Main',
        component: Main,
        redirect: {name: 'uploaded-files'},
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'profile',
                name: 'Profile',
                component: Profile,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'archivos-cargados',
                name: 'uploaded-files',
                component: UploadedFilesList,
                meta: {
                    requiresAuth: true,
                    authorize: [permissions.SEE_LOGS]
                }
            },
            {
                path: 'archivos-cargados/:idFile/detalle',
                name: 'uploaded-file-detail',
                component: UploadedFileDetail,
                meta: {
                    requiresAuth: true,
                    authorize: [permissions.SEE_DETAIL_LOG]
                }
            }
            // {
            //     path: '',
            //     name: 'Dashboard',
            //     component: Dashboard,
            //     meta: {
            //         requiresAuth: true
            //     }
            // }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresUnauth: true
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            requiresUnauth: true
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            requiresUnauth: true
        }
    },
    {
        path: '/recover-password',
        name: 'RecoverPassword',
        component: RecoverPassword,
        meta: {
            requiresUnauth: true
        }
    },
    {
        path: '/permission-denied',
        name: 'permission-denied',
        component: PermissionDenied,
        meta: {
            requiresAuth: true
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !store.getters.token) {
        next('/login');
    }

    //
    if (
        to.meta.requiresUnauth &&
        to.name === 'Login' &&
        !!store.getters.token
    ) {
        next('/archivos-cargados');
    }

    //check if route is restricted by role
    const authorize: [string] = <[string]>to.meta.authorize;
    if (
        authorize &&
        !authorize.some((permission) => ability.can(permission, 'VIGIFLOW'))
    ) {
        // role not authorised so redirect to home page
        router.push({name: 'permission-denied'}).catch((error) => error);
    }

    next();
});

export default router;
