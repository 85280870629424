import Repository from './Repository';

const resource = 'uploaded-files';

export default {
    getPaginated(
        initialDate: string = null,
        finalDate: string = null,
        page: number,
        limit: number
    ) {
        return Repository.get(
            `${resource}/paginate?page=${page}&limit=${limit}${
                initialDate ? `&initialDate=${initialDate}` : ''
            }${finalDate ? `&finalDate=${finalDate}` : ''}`
        );
    },
    getDetail(idFile: number) {
        return Repository.get(`${resource}/${idFile}/detail`);
    }
};
