import axios from 'axios';

const Repository = {
    init(baseURL: string) {
        axios.defaults.baseURL = baseURL;
    },
    setHeaders() {
        // axios.defaults.headers.common["Authorization"] =
        //   "Bearer " + TokenService.getToken();
    },
    removeHeaders() {
        axios.defaults.headers.common = {};
    },
    get(resource: string) {
        return axios.get(resource);
    },
    post(resource: string, data: any) {
        return axios.post(resource, data);
    },
    put(resource: string, data: any) {
        return axios.put(resource, data);
    },
    delete(resource: string) {
        return axios.delete(resource);
    },
    patch(resource: any, data: any) {
        return axios.patch(resource, data);
    },
    postFile(resource: string, file: any) {
        const formData = new FormData();
        const headers = {'Content-Type': 'multipart/form-data'};
        formData.append('attachment', file);
        return axios.post(resource, formData, {headers});
    }
};

export default Repository;
