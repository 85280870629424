import {createStore} from 'vuex';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
const ls = new SecureLS({isCompression: false});

export default createStore({
    state: {
        token: null,
        user: null,
        permissions: null,
        vflowInitialDate: null,
        vflowFinalDate: null
    },
    mutations,
    actions,
    getters,
    modules: {},
    plugins: [
        createPersistedState({
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key)
            }
        })
    ]
});
