import {IUser} from '@/interfaces/user';
import UploadedFilesRepository from '@/repositories/UploadedFilesRepository';
import {Options, Vue} from 'vue-class-component';
import moment from 'moment';
import ability from '@/config/ability';

@Options({
    name: 'roles-list',
    mounted() {
        this.initialDate = this.vFlowInitialDate;
        this.finalDate = this.vFlowFinalDate;
        this.getResults();
    }
})
export default class RolesList extends Vue {
    files: any[] = [];
    page = 1;
    records = 0;
    perPage = 10;
    initialDate: Date;
    finalDate: Date;
    moment: any;
    loading: boolean = false;
    optionPerPage: number[] = [5, 10, 15, 20];
    optionsPaginate = {
        texts: {
            count: `Mostrando {from} a {to} de {count} registros | {count} registros | Un registro`
        }
    };
    ability = ability;

    created() {
        this.moment = moment;
    }

    get user(): IUser {
        return this.$store.getters.user;
    }

    get vFlowInitialDate(): Date {
        return this.$store.getters.vflowInitialDate;
    }

    get vFlowFinalDate(): Date {
        return this.$store.getters.vflowFinalDate;
    }

    async getResults() {
        this.loading = true;
        const {data} = await UploadedFilesRepository.getPaginated(
            this.initialDate
                ? moment(this.initialDate).format('YYYY-MM-DD')
                : null,
            this.finalDate ? moment(this.finalDate).format('YYYY-MM-DD') : null,
            this.page,
            this.perPage
        );

        this.records = data.total;
        this.files = data.data;
        this.loading = false;
    }

    changeInitialDate() {
        console.log('this.initialDate :>> ', this.initialDate.toString().length);
        if (!this.initialDate) {
            this.initialDate = null;
            this.$store.dispatch('vFlowInitialDate', null);
            return;
        }

        const date = moment(this.initialDate).format('YYYY-MM-DD');
        this.$store.dispatch('vFlowInitialDate', date);
        document.getElementById('fechaFinal').setAttribute('min', date);
        this.initialDate = this.vFlowInitialDate;
    }
    changeFinalDate() {
        if (!this.finalDate) {
            this.finalDate = null;
            this.$store.dispatch('vFlowFinalDate', null);
            return;
        }
        const date = moment(this.finalDate).format('YYYY-MM-DD');
        this.$store.dispatch('vFlowFinalDate', date);
        this.finalDate = this.vFlowFinalDate;
    }

    checkDates(e: any) {
        e.preventDefault();

        this.getResults();
    }

    async rowsPerPage() {
        this.loading = true;
        this.page !== 1 ? (this.page = 1) : '';
        await this.getResults();
    }

    async goDetailFile(roleId: number) {
        this.$router.push({
            name: 'uploaded-file-detail',
            params: {idFile: roleId}
        });
    }
}
