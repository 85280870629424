import {IUser} from '@/interfaces/user';
import router from '@/router/index';

export default {
    login: (context: any, payload: string): any => {
        context.commit('setToken', payload);
        //router.replace('/archivos-cargados');
    },
    getUser: (context: any, payload: IUser): any => {
        context.commit('setUser', payload);
    },
    permissions: (context: any, payload: []): any => {
        context.commit('setPermissions', payload);
    },
    logout: (context: any) => {
        context.commit('setToken', null);
        context.commit('setUser', null);
        localStorage.removeItem('gatekeeper_token');
        router.replace('/login');
    },
    vFlowInitialDate: (context: any, payload: Date): any => {
        context.commit('setVFlowInitialDate', payload);
    },
    vFlowFinalDate: (context: any, payload: Date): any => {
        context.commit('setVFlowFinalDate', payload);
    }
};
