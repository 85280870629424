import Repository from './Repository';
import SecurityInstance from "../utils/securityInstanceAxios";
import { AxiosResponse } from 'axios';

const resource = 'auth';

const getError = (error: AxiosResponse) => {
    const message =
        (error) ||
        'Failed';
    return message;
};

export const authenticate = async (data:any) => {
    try {
        return await SecurityInstance.post(`${resource}/login`, data);
    } catch (error: any) {
        throw getError(error);
    }
};


export const logout = async () => {
    try {
        return await SecurityInstance.post(`${resource}/logout`, {});
    } catch (error: any) {
        throw getError(error);
    }
};

export const refreshToken = async () => {
    try {
        return await SecurityInstance.post(`${resource}/refresh`, {});
    } catch (error: any) {
        throw getError(error);
    }
};



