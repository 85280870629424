import {IUser} from '@/interfaces/user';
import {Options, Vue} from 'vue-class-component';
import MenuItem from '@/components/menu-item/menu-item.vue';
import {permissions} from '@/enum/applicationPermissions';
import {defineRulesFor} from '@/config/rules';
import ability from '@/config/ability';

@Options({
    name: 'app-menu-sidebar',
    components: {
        'app-menu-item': MenuItem
    }
})
export default class MenuSidebar extends Vue {
    public menu: [any] = [null];
    ability: any = null;

    get user(): IUser {
        return this.$store.getters.user;
    }

    created(): void {
        defineRulesFor();
        this.AllMenuOptions();
    }

    public AllMenuOptions() {
        this.menu = [
            {
                name: 'Vigiflow',
                accessPermission: '',
                children: [
                    {
                        name: 'Archivos',
                        path: '/archivos-cargados',
                        icon: 'fas fa-file-excel',
                        accessPermission: ability.can(
                            permissions.SEE_LOGS,
                            'VIGIFLOW'
                        )
                    }
                ]
            }
        ];
    }

    OptionsAllowedUser() {
        // `this` points to the vm instance
        return this.menu
            .filter((option: any) =>
                option.children
                    ? option.children.some(
                          (child: any) => child.accessPermission === true
                      )
                    : option.accessPermission
            )
            .map((option: any) => {
                const children = option.children?.filter(
                    (child: any) => child.accessPermission
                );

                return {
                    ...option,
                    children: children
                };
            });
    }
}

// export const MENU = [
//     // {
//     //     name: 'labels.dashboard',
//     //     path: '/'
//     // },
//     {
//         name: 'Vigiflow',
//         accessPermission: '',
//         children: [
//             {
//                 name: 'Archivos',
//                 path: '/archivos-cargados',
//                 icon: 'fas fa-file-excel',
//                 accessPermission: this.ability.can(
//                     'permissions.SEE_SURVEYS_PASI'
//                 )
//             }
//         ]
//     }
// ];
