import {AbilityBuilder, Ability, AbilityClass} from '@casl/ability';

import ability from './ability';

import store from '../store';
import {string} from 'yup';

type PossibleAbilities = string;

export function getPermission(): [] | [string] {
    const permissions = store.state.permissions || [];
    return permissions;
}

type Subject = 'VIGIFLOW';
type Action = string;

export type AppAbility = Ability<[Action, Subject]>;

export const AppAbility = Ability as AbilityClass<AppAbility>;

export function defineRulesFor() {
    const {can, rules} = new AbilityBuilder(AppAbility);
    const permissions = getPermission();

    if (permissions.length) {
        permissions.forEach((permission) => {
            can(permission, 'VIGIFLOW');
        });
    }

    return ability.update(rules);
}
