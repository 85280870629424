import {Options, Vue} from 'vue-class-component';
import {loginByAuth, loginByGoogle, loginByFacebook} from '@/repositories/auth';
import {authenticate} from '@/repositories/AuthRepository';
import Checkbox from '@/components/checkbox/checkbox.vue';
import Input from '@/components/input/input.vue';
import Button from '@/components/button/button.vue';
import {useToast} from 'vue-toastification';
import {AxiosResponse} from 'axios';
import {defineRulesFor} from '@/config/rules';

@Options({
    components: {
        'app-checkbox': Checkbox,
        'app-input': Input,
        'app-button': Button
    }
})
export default class Login extends Vue {
    private appElement: HTMLElement | null = null;
    public email: string = '';
    public password: string = '';
    public rememberMe: boolean = false;
    public isAuthLoading: boolean = false;
    public isFacebookLoading: boolean = false;
    public isGoogleLoading: boolean = false;
    private toast = useToast();

    public mounted(): void {
        this.appElement = document.getElementById('app') as HTMLElement;
        this.appElement.classList.add('login-page');
    }

    public unmounted(): void {
        (this.appElement as HTMLElement).classList.remove('login-page');
    }

    public async loginByAuth(): Promise<void> {
        try {
            this.isAuthLoading = true;

            const data = {
                email: this.email,
                password: this.password,
                applicationId: process.env.VUE_APP_APLICATION_ID
            };

            authenticate(data)
                .then((response: AxiosResponse) => {
                    this.$store.dispatch('login', response.data.access_token);
                    this.$store.dispatch('getUser', response.data.user);
                    this.$store.dispatch(
                        'permissions',
                        response.data.permissions.map(
                            (permission: any) => permission.name
                        )
                    );

                    defineRulesFor();

                    this.toast.success('Inicio de sesión exitoso');
                    this.$router.replace('/archivos-cargados');
                })
                .catch(({status, data}) => {
                    if (status === 401 && data.error) {
                        this.toast.error(
                            'Correo y/o contraseña incorrectos, intente nuevamente.'
                        );
                    }

                    if (
                        status === 409 &&
                        data?.error === 'USER_MUST_CHANGE_PASSWORD'
                    ) {
                        this.redirectLoginSecurity();
                        this.toast.info(
                            `Debes cambiar tu contraseña, antes de poder iniciar sesion, por favor revisa tu correo y encontraras una contraseña temporal la cual debes utilizar aquí`
                        );
                    }
                })
                .finally(() => {
                    this.isAuthLoading = false;
                });
        } catch (error: any) {
            console.log(error);
            this.toast.error(error.message);
            this.isAuthLoading = false;
        }
    }

    public redirectSecurity() {
        window.location.href = `${
            process.env.VUE_APP_SECURITY_FRONT
        }/forgot-password?urlFrom=${encodeURIComponent(window.location.href)}`;
    }

    public redirectLoginSecurity() {
        window.location.href = `${process.env.VUE_APP_SECURITY_FRONT}/login`;
    }
}
