import {IUser} from '@/interfaces/user';
import UploadedFilesRepository from '@/repositories/UploadedFilesRepository';
import {Options, Vue} from 'vue-class-component';
import moment from 'moment';
import xlsx, {ISettings} from 'json-as-xlsx';

@Options({
    name: 'roles-list',
    mounted() {
        const idFile = parseInt(this.$route.params.idFile.toString());

        if (idFile > 0) {
            this.fileId = idFile;
            this.getResults();
        } else {
            this.$router.push({
                name: 'uploaded-files'
            });
        }
    }
})
export default class AssociatedPermissionsList extends Vue {
    records: any[] = [];
    logs: any[] = [];
    fileId: number = 2;
    loading: boolean = false;
    btnLoading: boolean = false;
    moment: any;

    get user(): IUser {
        return this.$store.getters.user;
    }

    created() {
        this.moment = moment;
    }

    async getResults() {
        this.loading = true;
        const {data} = await UploadedFilesRepository.getDetail(this.fileId);
        this.records = data.detail;
        this.logs = data.logs;
        this.loading = false;
    }

    downloadFile() {
        this.btnLoading = true;
        const logs = this.logs;
        const records = this.records;

        let data = [
            {
                sheet: 'Registros procesados',
                columns: [
                    {label: 'Código PNF', value: 'codigo_pnf'},
                    {label: 'Fecha del reporte', value: 'fecha_del_reporte'},
                    {label: 'Nombre notificador', value: 'nombre_notificador'},
                    {label: 'Tipo documento', value: 'id_del_reporte'},
                    {
                        label: 'Número de documento',
                        value: 'nro_identificacion_paciente'
                    },
                    {label: 'Iniciales', value: 'iniciales'},
                    {label: 'Medicamento Implicado', value: 'medicamentoImplicado'},
                    {label: 'Nombre comercial del medicamento', value: 'nombre_comercial_del_medicamento'},
                    {label: 'Estado', value: 'estado'},
                    {label: 'Código Vigiflow', value: 'id_reporte'}
                ],
                content: records
            },
            {
                sheet: 'Logs de errores',
                columns: [
                    {label: 'Línea', value: 'linea'},
                    {label: 'Error', value: 'error'},
                    {label: 'Fecha de creación', value: 'fecha_creacion'},
                    {label: 'Id archivo', value: 'id_archivo'},
                    {label: 'Cédula', value: 'cedula'}
                ],
                content: logs
            }
        ];

        data =
            records.length && logs.length
                ? data
                : records.length
                ? [data[0]]
                : [data[1]];

        const settings: ISettings = {
            fileName: `Reporte_farmacovigilancia_${this.fileId}`,
            writeOptions: {
                bookType: 'xlsx'
            }
        };
        const callback = () => {
            this.btnLoading = false;
        };

        return xlsx(data, settings, callback);
    }

    decodeStringUTF8(texto: string) {
        return decodeURIComponent(escape(texto));
    }

    goToUploadedFiles() {
        this.$router.push({name: 'uploaded-files'});
    }
}
