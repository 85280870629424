import {Options, Vue} from 'vue-class-component';
import {logout} from '@/repositories/AuthRepository';
import Checkbox from '@/components/checkbox/checkbox.vue';
import Input from '@/components/input/input.vue';
import Button from '@/components/button/button.vue';
import {useToast} from 'vue-toastification';
import ability from '@/config/ability';

@Options({
    components: {
        'app-checkbox': Checkbox,
        'app-input': Input,
        'app-button': Button
    }
})
export default class Login extends Vue {
    private appElement: HTMLElement | null = null;
    public email: string = '';
    public password: string = '';
    public isLogoutLoading: boolean = false;
    private toast = useToast();
    private token: string = null;
    private ability = ability;

    public mounted(): void {
        this.appElement = document.getElementById('app') as HTMLElement;
        this.appElement.classList.add('login-page');
        this.token = this.$store.getters.token;
    }

    public unmounted(): void {
        (this.appElement as HTMLElement).classList.remove('login-page');
    }

    public async logout(): Promise<void> {
        try {
            this.isLogoutLoading = true;

            await logout()
                .then(() => {
                    this.$store.dispatch('logout');
                })
                .finally(() => {
                    this.isLogoutLoading = false;
                });
        } catch (error: any) {
            console.log(error);
            this.toast.error(error.message);
            this.isLogoutLoading = false;
        }
    }

    public async backToDashboard(): Promise<void> {
        try {
            this.$router.push({name: 'Main'});
        } catch (error: any) {
            console.log(error);
        }
    }
}
