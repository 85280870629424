import {IUser} from '@/interfaces/user';
import {Options, Vue} from 'vue-class-component';
import Dropdown from '@/components/dropdown/dropdown.vue';
import { logout } from '@/repositories/AuthRepository';
import Button from '@/components/button/button.vue';

@Options({
    name: 'user-dropdown',
    components: {
        'app-dropdown': Dropdown,
        'app-button': Button
    }
})
export default class User extends Vue {
    isLogoutLoading: boolean = false;

    get user(): IUser {
        return this.$store.getters.user;
    }

    private async logout() {
        this.isLogoutLoading = true;
        await logout().then(() => {
            this.$store.dispatch('logout');
        }).finally(()=>{
            this.isLogoutLoading= false;
        });
        
    }
}
